//  Core

.alerts-alternate {
  &.MuiAlert-root {
    border-width: 2px;
    border-style: solid;
    @include border-radius($border-radius-sm);
    border-left-width: 6px;
  }
}

.MuiSnackbar-root {
  z-index: 2500;

  .MuiSnackbarContent-root {
    color: $white;
  }
}

@each $color, $value in $theme-colors {
  .toastr-#{$color} {
    .MuiSnackbarContent-root {
      background: $value;
      box-shadow: 0 0.313rem 0.8rem rgba($value, 0.5), 0 0.126rem 0.225rem rgba($value, 0.3) !important;
    }
  }
}

.MuiAlert-standardWarning {
  border-color: $warning;
}

.MuiAlert-standardError {
  border-color: $danger;
}

.MuiAlert-standardSuccess {
  border-color: $success;
}

.MuiAlert-standardInfo {
  border-color: $info;
}

