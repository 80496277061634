//  Variables

$theme-configurator-drawer-bg:              $white;
$theme-configurator-drawer-width:           420px;
$theme-configurator-btn-size:               78px;
$theme-configurator-heading-font-size:      $font-size-sm;
$theme-configurator-heading-color:          $gray-900;
$theme-configurator-heading-bg:             $gray-100;
$theme-configurator-heading-border-color:   $gray-300;
$theme-configurator-open-box-shadow:        $box-shadow-layout-left;
$theme-configurator-transition:             all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
